body{ 
    display:flex; 
    flex-direction:column; 
    font-family: Arial, sans-serif, Helvetica,;
    background-color: white;
  }


.HomeContainer {
    /* background-color: gray; */
   
}

.homeTitle {
color: #007FFF;

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .HomeContainer {
        /* background-color: gray; */
    }
    
    .homeTitle {
    color: #007FFF;
    font-weight:bold;
    position:relative;
    bottom:30px;
    background:white;
    }

}