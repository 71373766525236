body {
    
}

.search-container {
    position: relative;
     bottom:  45px;
     
   
 }


 .TextField_SearchBox {
    background:white;
 }
  
@media only screen and (max-width: 1023px) {

.search-container {
    min-width:95%;
  
}
.SelectLocationDialog {

 
}
  }