/* Footer.css */

  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 10px;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .footer-section {
      flex: 1 0 100%;
      margin: 0;
    }
  }
  

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    min-width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }