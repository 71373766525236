.Searchbox {
    
    /* display:grid;
    margin-left:50px;
    min-width:200% */
 
    place-items: center;
    }


    .Searchbox {
        min-width: 200px;
          
    }

     .SearchTextField {
        /* background:#ddd; */
        max-width: 50%;
        position:relative; 
        bottom:30px;
     }

@media only screen and (max-width: 600px) {
  
    .Searchbox {
        min-width: 100%;
         
    }

     .SearchTextField {
        /* background:lightblue; */
         
     }
    
  }

  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .Searchbox {
       
        
    }

}
