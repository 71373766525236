.container {
    /* background:blue */
}

.shipping-address {
    /* padding-left: 20px; */
    /* border-bottom: 1px solid #000; */
}

table {
    /* border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd; */
    
  }
  
  th, td {
    /* text-align: left;
    padding: 8px; */
  }

  /* tr:nth-child(even){background-color: #f2f2f2} */

  .ProductLink {
    margin-left: 10px;
    font-size: 20px;
  }
  .ProductLink a  {
    color: rgb(0, 127, 255);
    font-weight: bold;
    text-decoration: none; /* removes the underline */
    font-weight: bold; /* makes the text bold */
  }

  .ProductLink a:hover {
    color: rgb(51, 153, 255);
  }