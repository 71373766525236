/* Style for the slider container */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  
  background-size: cover;
  padding: 40px 20px;
  /* border-radius: 40px; */
 
 
}
/* Style for the slider dots */
.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  display: none;
}

.dot {
  height: 8px; /* Decreased height */
  width: 8px; /* Decreased width */
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  margin: 0 6px; /* Decreased margin */
  cursor: pointer;
  transition: background-color 0.3s ease;
 
 
}

.dot:hover, .dot.active {
  background-color: rgba(255, 255, 255, 1);
}

.active {
  background-color: #fff;
}

/* Style for the slider */
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  
}

/* Style for the slider wrapper */
.slider-wrapper {
  display: flex;
  transition: transform 0.4s ease-in-out;
}

/* Style for each slide */
.slide {
  min-width: 100%;
  transition: opacity 0.6s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide.active {
  opacity: 1;
}

/* Style for the images */
.image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  /* border-radius: 20px; */
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
}

/* Style for the arrow buttons */
.left-arrow, .right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.left-arrow:hover, .right-arrow:hover {
  background-color: rgba(255, 255, 255, 1);
  /* color: rgb(0, 127, 255); */
}

/* Positioning for the left arrow */
.left-arrow {
  left: 10px;
  display: none;
}

/* Positioning for the right arrow */
.right-arrow {
  right: 10px;
  display: none;
}
 

/* Media queries for small devices */
@media (max-width: 600px) {
  .left-arrow, .right-arrow {
    padding: 10px;
    font-size: 18px;
  }

  .image {
    height: 200px;
  }
 
}
