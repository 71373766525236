.product-management-container {
    text-align: center;
  }
  
  .page-title {
    color: #333; /* Setze die gewünschte Textfarbe */
  }
  
  .maintenance-message {
    color: #666; /* Setze die gewünschte Farbe für die Wartungsnachricht */
    font-size: 16px; /* Passe die Schriftgröße an */
    margin-top: 10px; /* Passe den oberen Abstand an */
  }
  
  .image-container {
    margin-top: 20px; /* Passe den Abstand über dem Bild an */
  }
  
  .centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  

  .back-button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px; /* Add margin to separate the button from the title */
    margin-right: 80%; /* Add margin to move the button to the left */
  }
  
  