@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;400&display=swap);
@import url(https://fonts.googleapis.com);
@import url(https://fonts.gstatic.com);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
/* Footer.css */

  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .footer-section {
    flex: 1 1;
    margin: 0 10px;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .footer-section {
      flex: 1 0 100%;
      margin: 0;
    }
  }
  

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    min-width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
.Searchbox {
    
    /* display:grid;
    margin-left:50px;
    min-width:200% */
 
    place-items: center;
    }


    .Searchbox {
        min-width: 200px;
          
    }

     .SearchTextField {
        /* background:#ddd; */
        max-width: 50%;
        position:relative; 
        bottom:30px;
     }

@media only screen and (max-width: 600px) {
  
    .Searchbox {
        min-width: 100%;
         
    }

     .SearchTextField {
        /* background:lightblue; */
         
     }
    
  }

  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .Searchbox {
       
        
    }

}

body{ 
    display:flex; 
    flex-direction:column; 
    font-family: Arial, sans-serif, Helvetica,;
    background-color: white;
  }


.HomeContainer {
    /* background-color: gray; */
   
}

.homeTitle {
color: #007FFF;

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .HomeContainer {
        /* background-color: gray; */
    }
    
    .homeTitle {
    color: #007FFF;
    font-weight:bold;
    position:relative;
    bottom:30px;
    background:white;
    }

}
/* Style for the slider container */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  
  background-size: cover;
  padding: 40px 20px;
  /* border-radius: 40px; */
 
 
}
/* Style for the slider dots */
.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  display: none;
}

.dot {
  height: 8px; /* Decreased height */
  width: 8px; /* Decreased width */
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  margin: 0 6px; /* Decreased margin */
  cursor: pointer;
  transition: background-color 0.3s ease;
 
 
}

.dot:hover, .dot.active {
  background-color: rgba(255, 255, 255, 1);
}

.active {
  background-color: #fff;
}

/* Style for the slider */
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  
}

/* Style for the slider wrapper */
.slider-wrapper {
  display: flex;
  transition: transform 0.4s ease-in-out;
}

/* Style for each slide */
.slide {
  min-width: 100%;
  transition: opacity 0.6s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide.active {
  opacity: 1;
}

/* Style for the images */
.image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  /* border-radius: 20px; */
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
}

/* Style for the arrow buttons */
.left-arrow, .right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.left-arrow:hover, .right-arrow:hover {
  background-color: rgba(255, 255, 255, 1);
  /* color: rgb(0, 127, 255); */
}

/* Positioning for the left arrow */
.left-arrow {
  left: 10px;
  display: none;
}

/* Positioning for the right arrow */
.right-arrow {
  right: 10px;
  display: none;
}
 

/* Media queries for small devices */
@media (max-width: 600px) {
  .left-arrow, .right-arrow {
    padding: 10px;
    font-size: 18px;
  }

  .image {
    height: 200px;
  }
 
}

.h1Title {
    color: red
}


.NoOrders {
 
text-align: center 
}



#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    min-width: 80%;
    margin: auto;
  }
  
  #customers td, #customers th {
    border-bottom: 1px solid #ddd;
    padding: 24px;
  }
  
  /* #customers tr:nth-child(even){background-color: #f2f2f2;} */
  
  #customers  tr {background-color: #fff; cursor: pointer;}
  #customers  tr:hover {background-color: #ddd; cursor: pointer;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: black;
  }
body {
    
}

.search-container {
    position: relative;
     bottom:  45px;
     
   
 }


 .TextField_SearchBox {
    background:white;
 }
  
@media only screen and (max-width: 1023px) {

.search-container {
    min-width:95%;
  
}
.SelectLocationDialog {

 
}
  }
/* Import the Roboto font in CSS if not included in HTML */
/* Importing the fonts */


img {
    max-width:100%;
  }
  
    .grid-container {
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr 1fr;
        /* background-color: red; */
        padding: 10px;
        grid-gap:1rem;
        gap:1rem;
        margin: 100px;
        /* color:red; */
   
     font-family: Arial, sans-serif;
   
        
      }
      .grid-item {
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid #ddd;
        font-size: 30px;
        cursor:pointer;
        transition: transform 0.2s;
      
         
      }

      .grid-item:hover {
        transform: scale(1.05);
      }
      
      .searchPage {
        position: relative;
        bottom:90px
      }
 
        .Loader {
          display: grid;
          place-items: center;
        }


        .Product-desc {
          font-size: 16px;
          text-align: left;
          line-height: 1.5;
          color: rgb(15, 17, 17);
        }
  
        .Product-Price{
        margin-top:15px;
        font-weight: bold;
        }
  
  
    .Brand-Name {
      font-size: 16px;
      text-align: center;
      line-height: 1.5;
      color: rgb(15, 17, 17);
      font-weight:bold;
    }
  
  .Product-Name {
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    font-weight:bold;
    margin-top: 10px;
  }
  
  


.Product-Name:hover {
  color: #007FFF;
  text-decoration: none; /* Add underline on hover */
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1024px) {
.grid-container {
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(200px, 1fr));
    /* background-color: #fff; */
     padding:10px;
     grid-gap:0.3rem;
     gap:0.3rem;
    margin: 10px;
    /* color:orange; */
 
  }

   /*item Style */

   .Product-desc {
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    padding-left:5px
  }

  .Product-Price{
  margin-top:15px;
  font-weight: bold;
  padding-left:5px
  }
}

/* devices (phones, 1024px and down)
two columns for mobile phones*/
@media only screen and (max-width: 500px) {
.grid-container {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    /* background-color: #58bb26; */
     padding:3px;
     grid-gap:0.2rem;
     gap:0.2rem;
     margin: 1px;
    /* color:green; */
  }

  .Product-desc {
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    padding-left:5px
  }

  .Product-Price{
  margin-top:15px;
  font-weight: bold;
  padding-left:5px
  }


.Brand-Name {
font-size: 16px;
text-align: center;
line-height: 1.5;
color: rgb(15, 17, 17);
font-weight:bold;
}

   /*item Style */
}

@media only screen and (min-width: 825px) {
body {
/* background-color: red; */
margin:0;
}
.grid-container {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    /* background-color: #fff; */
    
    grid-gap:0.7rem;
    
    gap:0.7rem;
    margin: 100px auto;
    max-width:75rem;
    color:#000;
  }

/*item Style */

.grid-item {
background-color: rgba(255, 255, 255, 0.8);
border: 1px solid #ddd;
padding: 40px;
font-size: 30px;
text-align: center;
}
}


.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fed;
    border-color: rgb(166, 172, 181)transparent rgb(166, 172, 181)transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  
body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.stepperContainer {
  padding: 80px;
  place-items: center;
}
.accountTypeBox {
  display: grid;
  align-items: center;
  place-items: center;
  padding-top: 20px;
}
.Stepper {
  padding: 10px;
}
 
.signupTitle {
    text-align: center;
  }

  #h3_Title {
    margin-left: 10px;
}
#delivery_to_all_German_Cities {
    margin-left: 15px;
}
#textField {
    min-width: 300px;
 }
#password_TextField {
    min-width: 450px;
} 
#delivery_Locations_Title  {
     
    margin-left: 15px;
   
}
 
@media only screen and (max-width: 600px) {
  .Stepper {
    padding: 0px;
  }

  .Paper {
    min-width: 350px;
    padding-left: 2px;
    padding-top: 20px;
  }
  .stepperContainer {
    margin: auto;
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
  }

  .signupTitle {
    padding: 6px;
    /* width:400px; */
    font-size:18px;
    text-align: center;
  }
  
.delivery_Locations_Title {
    font-size: 13px;
    padding: 5px;
}
#textField {
   min-width: 300px;
}
#password_TextField {
    min-width: 260px;
}

#h3_Title {
    margin-left: 10px;
}
#delivery_to_all_German_Cities {
    margin-left: 5px;
}

}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    max-width: 100%;

    color: #fff;
    text-align: center;
    background-color:#007FFF
 }
.ListContainer {
/* background-color:red */
 
}
.ListItems {
   border-bottom: 1px solid #ddd
}

.ListHeader {
    color:red
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
        max-width: 43%;
    }
    .Searchbox {
        max-width: 43%;
        
    }
    .ListContainer {
        /* background-color:red; */
        /* background: orange; */
        /* max-width: 90%; */
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
             max-width: 100%;
             padding: 10px;
        }
  }


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
 

    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
    }
    .Searchbox {
        
        
    }
    .ListContainer {
        /* background-color:red; */
        /* background: orange; */
        max-width: 50%;
      
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
            
        }
  }
  

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
    }
    .Searchbox {
        
        
    }
    .ListContainer {
        /* background-color:red */
        /* background: orange; */
        max-width: 70%;
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
           
        }

  } 
 
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    /* position: relative;
    bottom: 20rem; */
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #c0bbbb;
    border-color: #c0bbbb transparent #c0bbbb transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.TableContainer {
    padding:20px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: scroll;
     
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .TableContainer {
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */
    
        /* background: orange; */
    
    }
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .TableContainer {
        /* background: pink; */
        max-width:60%;
        margin:auto;
    }
  }
  
.product-management-container {
    text-align: center;
  }
  
  .page-title {
    color: #333; /* Setze die gewünschte Textfarbe */
  }
  
  .maintenance-message {
    color: #666; /* Setze die gewünschte Farbe für die Wartungsnachricht */
    font-size: 16px; /* Passe die Schriftgröße an */
    margin-top: 10px; /* Passe den oberen Abstand an */
  }
  
  .image-container {
    margin-top: 20px; /* Passe den Abstand über dem Bild an */
  }
  
  .centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
body {
  font-family: Arial, Helvetica, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 35%; */
}

.sliderContainer {
  max-width: 800px;
  max-height: 30rem;
  margin: 0 auto;
  position: relative;
  top: 80px;
}

.backButton {
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 10px; /* Adjust the left position as needed */
  top: 80px; /* Adjust the top position as needed */
}




.shortName,
.productDescription,
.cityiesList,
.countrywideShipping,
.productPrice,
.btnsContainer {
  /* width: 100%; */
  /* max-width: 600px; */
  margin: 0 auto;
  position: relative;
  text-align: left;
}


.shortName {
  top: 50px;
}

.productDescription {
  bottom: 1px;
}

.cityiesList,
.countrywideShipping {
  top: 190px;
}

.productPrice {
  bottom: 100px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.btnsContainer {
  top: 190px;
  padding-bottom: 90px;
}

.submitOrderBtn {
  border-radius: 700px;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  background-color: #007FFF;
  color: #fff;
  top: 200px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}



.footer-section {
  width: calc(25% - 20px);
  margin-bottom: 40px;
}

.footer-section h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.product-page {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* padding: 20px; */
  margin-top: -10%;
   
}

.product-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.product-slider {
  flex: 1 1;
  max-width: 70%;
  margin-right: 20px;
}

.product-slider img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 80%;
}

.product-details h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-checklist {
  width: 100%;
  max-width: 600px;
  /* margin-bottom: 200px; */
  /* margin-left: 20rem; */
  position: relative;
  bottom: 25px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-checklist .MuiListItem-root {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.product-checklist .MuiListItem-root:last-child {
  border-bottom: none;
}

.product-checklist .MuiListItemIcon-root {
  min-width: 30px;
}

@media only screen and (max-width: 768px) {
  .product-content {
    flex-direction: column;
    align-items: center;
  }

  .product-slider,
  .product-details {
    max-width: 100%;
    margin: 0;
  }

  .product-details {
    margin-top: 20px;
  }

  .product-details h2 {
    font-size: 1.5rem;
  }

  .product-details p {
    font-size: 1rem;
  }

  .product-checklist {
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .product-content {
    flex-direction: column;
    align-items: center;
    margin-top: -20%;
  }

  .sliderContainer,
  .shortName,
  .productDescription,
  .cityiesList,
  .countrywideShipping,
  .productPrice,
  .btnsContainer {
    /* max-width: 100%; */
    padding: 0 10px;
  }

  .productPrice {
    font-size: 22px;
    top: 100px;
  }

  .btnsContainer {
    top: 240px;
    margin-left: 20px;
  }

  .product-checklist {
    padding: 10px;
  }
}

.h1Title {
    color: rgb(101, 98, 98);
    padding-left: 5px;
    border-left: 1px solid blue;
    font-weight: bolder;
}
.h3Title {
  margin-left:10px
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    
  }

 
  
  tr:nth-child(even){background-color: #f2f2f2}



/*  */
.container {
    /* background:blue */
}

.shipping-address {
    /* padding-left: 20px; */
    /* border-bottom: 1px solid #000; */
}

table {
    /* border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd; */
    
  }
  
  th, td {
    /* text-align: left;
    padding: 8px; */
  }

  /* tr:nth-child(even){background-color: #f2f2f2} */

  .ProductLink {
    margin-left: 10px;
    font-size: 20px;
  }
  .ProductLink a  {
    color: rgb(0, 127, 255);
    font-weight: bold;
    text-decoration: none; /* removes the underline */
    font-weight: bold; /* makes the text bold */
  }

  .ProductLink a:hover {
    color: rgb(51, 153, 255);
  }
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .MuiTextField-root {
    max-width: 25rem;
  }

  .TextField {
    max-width: 21rem;
  }
   
.btn-bestellen {
  max-width: 350px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}


.Box_Styles {
    padding-top:40px;
   display: grid;
   place-items: center;
    position: relative;
   top: 5px;
   
  }
 
  .Box_Styles h2 {
  padding-left: 5px;
   
  }

  .Paper {
    padding:25px;
    border-radius: 400px;
  }
 
.btn-bestellen {

}


* {
  font-family: "Roboto", sans-serif;
}

/* AboutUs.css */
 
  
  .wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
  
  
 
  
  .header {
    margin: 0 auto; /* Füge "px" hinzu, um den Pixelwert anzugeben */
    text-align: center;
  }
  
  

  
  .header {
    margin: 0 auto; /* Center "Über uns" horizontally */
    text-align: center;
     
  }

  
  
  .content {
    flex: 1 1;
    max-width: 800px;
    margin: 20px auto; /* Stelle sicher, dass die Einheiten korrekt sind */
    padding: 20px;
    line-height: 1.6;
  }
  
  
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #007fff;
    color: white;
    text-align: center;
  }
  
  
* {box-sizing: border-box}
body {font-family: Verdana, sans-serif; margin:0}
.mySlides {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: rgb(167, 163, 163);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
          user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: rgb(167, 163, 163);;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: rgb(167, 163, 163);
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: rgb(167, 163, 163);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
/* Your external CSS file */

.categorieTitle {
    position: relative;
    left: 18%;
    font-family: 'Arial, sans-serif';
     
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 600px) {
    .categorieTitle {
      left: 111%;
      color: red;
    }
  }
  
.product-management-container {
    text-align: center;
  }
  
  .page-title {
    color: #333; /* Setze die gewünschte Textfarbe */
  }
  
  .maintenance-message {
    color: #666; /* Setze die gewünschte Farbe für die Wartungsnachricht */
    font-size: 16px; /* Passe die Schriftgröße an */
    margin-top: 10px; /* Passe den oberen Abstand an */
  }
  
  .image-container {
    margin-top: 20px; /* Passe den Abstand über dem Bild an */
  }
  
  .centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  

  .back-button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px; /* Add margin to separate the button from the title */
    margin-right: 80%; /* Add margin to move the button to the left */
  }
  
  
/* AGB.css */

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header-content {
    padding: 20px;
  }
  
  .logo-link {
    text-decoration: none;
  }
  
  .logo {
    color: #007FFF;
    margin: 0;
  }
  
  .agb-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .agb-container h2 {
    margin-top: 20px;
  }
  
  .agb-content {
    margin-top: 20px;
  }
  
  .agb-content h3 {
    margin-top: 10px;
  }
  
  .agb-content ul {
    padding-left: 20px;
  }
  
  .agb-content p {
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: 768px) {
    .agb-container {
      padding: 0 10px;
    }
  }
  
/* Styles.css */

.impressum-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px; /* Added padding for better spacing on smaller screens */
}

.impressum-container p {
  margin-bottom: 15px;
}

.impressum-container h2 {
  margin-bottom: 10px;
}

.impressum-container h3 {
  margin-bottom: 5px;
}

.impressum-container hr {
  margin: 20px 0;
}

.impressum-container h2,
.impressum-container h3 {
  text-transform: capitalize;
}



/* Media Query for Small Screens */
@media screen and (max-width: 600px) {
  .impressum-container {
    max-width: 100%;
    padding: 0 10px; /* Adjusted padding for smaller screens */
  }
}

/* Datenschutz.css */

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header-content {
    padding: 20px;
  }
  
  .logo-link {
    text-decoration: none;
  }
  
  .logo {
    color: #007FFF;
    margin: 0;
  }
  
  .datenschutz-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .datenschutz-container h2 {
    margin-top: 20px;
  }
  
  .datenschutz-content {
    margin-top: 20px;
  }
  
  .datenschutz-content h3 {
    margin-top: 10px;
  }
  
  .datenschutz-content ul {
    padding-left: 20px;
  }
  
  .datenschutz-content p {
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: 768px) {
    .datenschutz-container {
      padding: 0 10px;
    }
  }
  
body{ 
    display:flex; 
    flex-direction:column; 
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
  }


.HomeContainer {
    /* background-color: gray; */
   
}


.brand-header {
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .brand-header h1 {
    font-size: 2.5em;
    color: #333;
    margin: 0;
  }

.homeTitle {
color: #007FFF;

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .HomeContainer {
        /* background-color: gray; */
    }
    
    .homeTitle {
    color: #007FFF;
    font-weight:bold;
    position:relative;
    bottom:30px;
    background:white;
    }

}
