/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .MuiTextField-root {
    max-width: 25rem;
  }

  .TextField {
    max-width: 21rem;
  }
   
.btn-bestellen {
  max-width: 350px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}


.Box_Styles {
    padding-top:40px;
   display: grid;
   place-items: center;
    position: relative;
   top: 5px;
   
  }
 
  .Box_Styles h2 {
  padding-left: 5px;
   
  }

  .Paper {
    padding:25px;
    border-radius: 400px;
  }
 
.btn-bestellen {

}

