.h1Title {
    color: red
}


.NoOrders {
 
text-align: center 
}



#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    min-width: 80%;
    margin: auto;
  }
  
  #customers td, #customers th {
    border-bottom: 1px solid #ddd;
    padding: 24px;
  }
  
  /* #customers tr:nth-child(even){background-color: #f2f2f2;} */
  
  #customers  tr {background-color: #fff; cursor: pointer;}
  #customers  tr:hover {background-color: #ddd; cursor: pointer;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: black;
  }