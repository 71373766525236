/* Your external CSS file */

.categorieTitle {
    position: relative;
    left: 18%;
    font-family: 'Arial, sans-serif';
     
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 600px) {
    .categorieTitle {
      left: 111%;
      color: red;
    }
  }
  