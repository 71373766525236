/* Styles.css */

.impressum-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px; /* Added padding for better spacing on smaller screens */
}

.impressum-container p {
  margin-bottom: 15px;
}

.impressum-container h2 {
  margin-bottom: 10px;
}

.impressum-container h3 {
  margin-bottom: 5px;
}

.impressum-container hr {
  margin: 20px 0;
}

.impressum-container h2,
.impressum-container h3 {
  text-transform: capitalize;
}



/* Media Query for Small Screens */
@media screen and (max-width: 600px) {
  .impressum-container {
    max-width: 100%;
    padding: 0 10px; /* Adjusted padding for smaller screens */
  }
}
