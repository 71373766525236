.h1Title {
    color: rgb(101, 98, 98);
    padding-left: 5px;
    border-left: 1px solid blue;
    font-weight: bolder;
}
.h3Title {
  margin-left:10px
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    
  }

 
  
  tr:nth-child(even){background-color: #f2f2f2}



/*  */