.ListContainer {
/* background-color:red */
 
}
.ListItems {
   border-bottom: 1px solid #ddd
}

.ListHeader {
    color:red
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
        max-width: 43%;
    }
    .Searchbox {
        max-width: 43%;
        
    }
    .ListContainer {
        /* background-color:red; */
        /* background: orange; */
        /* max-width: 90%; */
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
             max-width: 100%;
             padding: 10px;
        }
  }


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
 

    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
    }
    .Searchbox {
        
        
    }
    .ListContainer {
        /* background-color:red; */
        /* background: orange; */
        max-width: 50%;
      
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
            
        }
  }
  

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .SearchboxContainer {
        /* display: grid;
        place-items: left; */
        /* min-width: 70%; */
        /* background: orange; */
    }
    .Searchbox {
        
        
    }
    .ListContainer {
        /* background-color:red */
        /* background: orange; */
        max-width: 70%;
        }
        .ListItems {
           border-bottom: 1px solid #ddd;
           /* background: orange; */
           
        }

  } 
 