/* AboutUs.css */
 
  
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  
 
  
  .header {
    margin: 0 auto; /* Füge "px" hinzu, um den Pixelwert anzugeben */
    text-align: center;
  }
  
  

  
  .header {
    margin: 0 auto; /* Center "Über uns" horizontally */
    text-align: center;
     
  }

  
  
  .content {
    flex: 1;
    max-width: 800px;
    margin: 20px auto; /* Stelle sicher, dass die Einheiten korrekt sind */
    padding: 20px;
    line-height: 1.6;
  }
  
  
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #007fff;
    color: white;
    text-align: center;
  }
  
  