/* AGB.css */

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header-content {
    padding: 20px;
  }
  
  .logo-link {
    text-decoration: none;
  }
  
  .logo {
    color: #007FFF;
    margin: 0;
  }
  
  .agb-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .agb-container h2 {
    margin-top: 20px;
  }
  
  .agb-content {
    margin-top: 20px;
  }
  
  .agb-content h3 {
    margin-top: 10px;
  }
  
  .agb-content ul {
    padding-left: 20px;
  }
  
  .agb-content p {
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: 768px) {
    .agb-container {
      padding: 0 10px;
    }
  }
  