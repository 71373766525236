body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.stepperContainer {
  padding: 80px;
  place-items: center;
}
.accountTypeBox {
  display: grid;
  align-items: center;
  place-items: center;
  padding-top: 20px;
}
.Stepper {
  padding: 10px;
}
 
.signupTitle {
    text-align: center;
  }

  #h3_Title {
    margin-left: 10px;
}
#delivery_to_all_German_Cities {
    margin-left: 15px;
}
#textField {
    min-width: 300px;
 }
#password_TextField {
    min-width: 450px;
} 
#delivery_Locations_Title  {
     
    margin-left: 15px;
   
}
 
@media only screen and (max-width: 600px) {
  .Stepper {
    padding: 0px;
  }

  .Paper {
    min-width: 350px;
    padding-left: 2px;
    padding-top: 20px;
  }
  .stepperContainer {
    margin: auto;
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
  }

  .signupTitle {
    padding: 6px;
    /* width:400px; */
    font-size:18px;
    text-align: center;
  }
  
.delivery_Locations_Title {
    font-size: 13px;
    padding: 5px;
}
#textField {
   min-width: 300px;
}
#password_TextField {
    min-width: 260px;
}

#h3_Title {
    margin-left: 10px;
}
#delivery_to_all_German_Cities {
    margin-left: 5px;
}

}
