/* Import the Roboto font in CSS if not included in HTML */
/* Importing the fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;400&display=swap');


img {
    max-width:100%;
  }
  
    .grid-container {
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr 1fr;
        /* background-color: red; */
        padding: 10px;
        gap:1rem;
        margin: 100px;
        /* color:red; */
   
     font-family: Arial, sans-serif;
   
        
      }
      .grid-item {
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid #ddd;
        font-size: 30px;
        cursor:pointer;
        transition: transform 0.2s;
      
         
      }

      .grid-item:hover {
        transform: scale(1.05);
      }
      
      .searchPage {
        position: relative;
        bottom:90px
      }
 
        .Loader {
          display: grid;
          place-items: center;
        }


        .Product-desc {
          font-size: 16px;
          text-align: left;
          line-height: 1.5;
          color: rgb(15, 17, 17);
        }
  
        .Product-Price{
        margin-top:15px;
        font-weight: bold;
        }
  
  
    .Brand-Name {
      font-size: 16px;
      text-align: center;
      line-height: 1.5;
      color: rgb(15, 17, 17);
      font-weight:bold;
    }
  
  .Product-Name {
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    font-weight:bold;
    margin-top: 10px;
  }
  
  


.Product-Name:hover {
  color: #007FFF;
  text-decoration: none; /* Add underline on hover */
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1024px) {
.grid-container {
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(200px, 1fr));
    /* background-color: #fff; */
     padding:10px;
     gap:0.3rem;
    margin: 10px;
    /* color:orange; */
 
  }

   /*item Style */

   .Product-desc {
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    padding-left:5px
  }

  .Product-Price{
  margin-top:15px;
  font-weight: bold;
  padding-left:5px
  }
}

/* devices (phones, 1024px and down)
two columns for mobile phones*/
@media only screen and (max-width: 500px) {
.grid-container {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    /* background-color: #58bb26; */
     padding:3px;
     gap:0.2rem;
     margin: 1px;
    /* color:green; */
  }

  .Product-desc {
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
    color: rgb(15, 17, 17);
    padding-left:5px
  }

  .Product-Price{
  margin-top:15px;
  font-weight: bold;
  padding-left:5px
  }


.Brand-Name {
font-size: 16px;
text-align: center;
line-height: 1.5;
color: rgb(15, 17, 17);
font-weight:bold;
}

   /*item Style */
}

@media only screen and (min-width: 825px) {
body {
/* background-color: red; */
margin:0;
}
.grid-container {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    /* background-color: #fff; */
    
    gap:0.7rem;
    margin: 100px auto;
    max-width:75rem;
    color:#000;
  }

/*item Style */

.grid-item {
background-color: rgba(255, 255, 255, 0.8);
border: 1px solid #ddd;
padding: 40px;
font-size: 30px;
text-align: center;
}
}

