body{ 
    display:flex; 
    flex-direction:column; 
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
  }


.HomeContainer {
    /* background-color: gray; */
   
}


.brand-header {
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .brand-header h1 {
    font-size: 2.5em;
    color: #333;
    margin: 0;
  }

.homeTitle {
color: #007FFF;

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .HomeContainer {
        /* background-color: gray; */
    }
    
    .homeTitle {
    color: #007FFF;
    font-weight:bold;
    position:relative;
    bottom:30px;
    background:white;
    }

}