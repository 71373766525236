body {
  font-family: Arial, Helvetica, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 35%; */
}

.sliderContainer {
  max-width: 800px;
  max-height: 30rem;
  margin: 0 auto;
  position: relative;
  top: 80px;
}

.backButton {
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 10px; /* Adjust the left position as needed */
  top: 80px; /* Adjust the top position as needed */
}




.shortName,
.productDescription,
.cityiesList,
.countrywideShipping,
.productPrice,
.btnsContainer {
  /* width: 100%; */
  /* max-width: 600px; */
  margin: 0 auto;
  position: relative;
  text-align: left;
}


.shortName {
  top: 50px;
}

.productDescription {
  bottom: 1px;
}

.cityiesList,
.countrywideShipping {
  top: 190px;
}

.productPrice {
  bottom: 100px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.btnsContainer {
  top: 190px;
  padding-bottom: 90px;
}

.submitOrderBtn {
  border-radius: 700px;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  background-color: #007FFF;
  color: #fff;
  top: 200px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}



.footer-section {
  width: calc(25% - 20px);
  margin-bottom: 40px;
}

.footer-section h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.product-page {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* padding: 20px; */
  margin-top: -10%;
   
}

.product-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.product-slider {
  flex: 1;
  max-width: 70%;
  margin-right: 20px;
}

.product-slider img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 80%;
}

.product-details h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-checklist {
  width: 100%;
  max-width: 600px;
  /* margin-bottom: 200px; */
  /* margin-left: 20rem; */
  position: relative;
  bottom: 25px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-checklist .MuiListItem-root {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.product-checklist .MuiListItem-root:last-child {
  border-bottom: none;
}

.product-checklist .MuiListItemIcon-root {
  min-width: 30px;
}

@media only screen and (max-width: 768px) {
  .product-content {
    flex-direction: column;
    align-items: center;
  }

  .product-slider,
  .product-details {
    max-width: 100%;
    margin: 0;
  }

  .product-details {
    margin-top: 20px;
  }

  .product-details h2 {
    font-size: 1.5rem;
  }

  .product-details p {
    font-size: 1rem;
  }

  .product-checklist {
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .product-content {
    flex-direction: column;
    align-items: center;
    margin-top: -20%;
  }

  .sliderContainer,
  .shortName,
  .productDescription,
  .cityiesList,
  .countrywideShipping,
  .productPrice,
  .btnsContainer {
    /* max-width: 100%; */
    padding: 0 10px;
  }

  .productPrice {
    font-size: 22px;
    top: 100px;
  }

  .btnsContainer {
    top: 240px;
    margin-left: 20px;
  }

  .product-checklist {
    padding: 10px;
  }
}
