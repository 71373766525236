.TableContainer {
    padding:20px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: scroll;
     
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .TableContainer {
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */
    
        /* background: orange; */
    
    }
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .TableContainer {
        /* background: pink; */
        max-width:60%;
        margin:auto;
    }
  }
  